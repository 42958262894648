import { useState, useEffect, useRef } from "react";
import TagsInput from "./TagsInput";

interface TagHeaderProps {
  tagName: string;
  superTags: string[];
  onTagRenamed: (oldTag: string, newTag: string) => void;
}

function TagHeader({
  tagName: originalTagName,
  superTags: originalSuperTags,
  onTagRenamed,
}: TagHeaderProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [tagName, setTagName] = useState(originalTagName);
  const contentEditableRef = useRef<HTMLHeadingElement>(null);
  const [superTags, setSuperTags] = useState<string[]>(originalSuperTags);

  useEffect(() => {
    if (isEditing && contentEditableRef.current) {
      contentEditableRef.current.focus();
    }
  }, [isEditing]);

  const onEditClick = () => {
    setIsEditing(true);
  };

  const onSaveClick = () => {
    setIsEditing(false);
    onTagRenamed(originalTagName, tagName);
  };

  const onInput = (e: React.FormEvent<HTMLHeadingElement>) => {
    console.log("onInput");
    const text = e.currentTarget.textContent;
    console.log(text);
    setTagName(text || "");

    const isEnterPressed =
      (e.nativeEvent as InputEvent).inputType === "insertParagraph" ||
      (e.nativeEvent instanceof KeyboardEvent && e.nativeEvent.key === "Enter");
    if (isEnterPressed) {
      onSaveClick();
    }
  };

  return (
    <div className="tag-header-div">
      <h2
        ref={contentEditableRef}
        contentEditable={isEditing}
        suppressContentEditableWarning={isEditing}
        className="tag-name-header"
        onInput={onInput}
        onClick={onEditClick}
      >
        {originalTagName}
      </h2>
      {isEditing && (
        <TagsInput
          allExistingTags={superTags}
          placeholderText="Add a super tag..."
          tags={superTags}
          addTag={(tag) => setSuperTags([...superTags, tag])}
          removeTag={(index) =>
            setSuperTags(superTags.filter((_, idx) => idx !== index))
          }
        />
      )}
      {isEditing ? (
        <button onClick={onSaveClick} className="tag-header-save-btn">
          Save
        </button>
      ) : (
        // <button onClick={onEditClick} className="tag-header-edit-btn">
        //   Edit
        // </button>
        <></>
      )}
    </div>
  );
}

export default TagHeader;
