import { useState } from "react";

interface TagsInputProps {
  tags: string[];
  placeholderText?: string;
  addTag: (tag: string) => void;
  removeTag: (index: number) => void;
  allExistingTags: string[];
}

function TagsInput({
  tags,
  addTag,
  removeTag,
  placeholderText,
  allExistingTags,
}: TagsInputProps) {
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue) {
      event.preventDefault();
      addTag(inputValue);
      setInputValue("");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const newTags = allExistingTags.filter((tag) => tag !== inputValue);

  return (
    <div className="tags-input-container">
      <input
        type="text"
        list="tags-list"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholderText ?? "Add a tag..."}
        className="tags-input"
      />
      <datalist id="tags-list">
        {newTags.map((tag, index) => (
          <option key={index} value={tag} />
        ))}
      </datalist>
      <div className="tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <button type="button" onClick={() => removeTag(index)}>
              x
            </button>
          </span>
        ))}
      </div>
    </div>
  );
}

export default TagsInput;
