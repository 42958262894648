import { useEffect, useRef, useState } from "react";
import "./App.css";
import Omnibox, { OnFormSubmitType, OnInputChangeType } from "./Omnibox";
import BookmarksBrowseView from "./BookmarksBrowseView";
import { Session, createClient } from "@supabase/supabase-js";
import LogInComponent from "./LogInComponent";

const localStorageBookmarksKey = "bookmarks";
const localStorageUpdatedAtKey = "updatedAt";
const localStorageCurrentWorkspaceKey = "currentWorkspace";

const supabase = createClient(
  "https://tbspnvnooofrycnvhdkb.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRic3Budm5vb29mcnljbnZoZGtiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDYzOTM2MDksImV4cCI6MjAyMTk2OTYwOX0.unUtjAoeXKDJLsCCR9k5umB-qz7F2w7_nh6Fz_c1xT0"
);
const bookmarksFileName = "bookmarks.json";

export interface Bookmark {
  label: string;
  url: string;
  tags: Record<string, string[]>;
}

async function getUser() {
  if (supabase?.auth) {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (session != null) {
      return session.user;
    }
  }
}

async function getServerUpdatedAt() {
  const user = await getUser();
  if (user != null) {
    const { data, error } = await supabase.storage.from("vault").list(user.id);
    const file = data?.find((file) => file.name === bookmarksFileName);
    if (error == null && file != null) {
      return new Date(file.updated_at).getTime();
    }
  }
  return null;
}

async function downloadBookmarksFromCloud(userId: string) {
  const filePath = `${userId}/${bookmarksFileName}?bust=${Date.now()}`;
  return await supabase.storage.from("vault").download(filePath);
}

async function pullBookmarks(
  serverUpdatedAt: number | null,
  setBookmarks: React.Dispatch<React.SetStateAction<Bookmark[]>>
) {
  try {
    localStorage.setItem(
      localStorageUpdatedAtKey,
      (serverUpdatedAt ?? new Date().getTime()).toString()
    );
    const user = await getUser();
    if (user != null) {
      const { data, error } = await downloadBookmarksFromCloud(user.id);
      const rawText = await data?.text();
      setBookmarks(JSON.parse(rawText ?? "{}"));
    }
  } catch (e) {
    console.error(e);
  }
}

async function uploadBookmarksToCloud(bookmarks: Bookmark[]) {
  const user = await getUser();
  if (user != null) {
    const filePath = `${user.id}/${bookmarksFileName}`;
    const { data, error } = await supabase.storage
      .from("vault")
      .upload(filePath, JSON.stringify(bookmarks), { upsert: true });
    console.log({ data, error });
    return { data, error };
  } else {
    return {};
  }
}

async function pushBookmarks(bookmarks: Bookmark[]) {
  localStorage.setItem(
    localStorageUpdatedAtKey,
    new Date().getTime().toString()
  );
  try {
    const user = await getUser();
    if (user != null) {
      const { data, error } = await uploadBookmarksToCloud(bookmarks);
    }
  } catch (e) {
    console.error(e);
  }
}

function App() {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>(() => {
    const localData = localStorage.getItem(localStorageBookmarksKey);
    return localData ? JSON.parse(localData) : [];
  });
  const [currentWorkspace, setCurrentWorkspace] = useState<string>(() => {
    return localStorage.getItem(localStorageCurrentWorkspaceKey) ?? "";
  });
  const [inputValue, setInputValue] = useState("");
  const [session, setSession] = useState<Session | null>(null);
  const [isOmniboxDominant, setIsOmniboxDominant] = useState(true);
  const [isSearchRequested, setIsSearchRequested] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (session?.user?.email != null) {
      getServerUpdatedAt().then(async (serverUpdatedAt) => {
        const localUpdatedAt = localStorage.getItem(localStorageUpdatedAtKey)
          ? parseInt(localStorage.getItem(localStorageUpdatedAtKey)!)
          : null;
        const secondsAheadOfServer =
          ((localUpdatedAt ?? NaN) - (serverUpdatedAt ?? NaN)) / 1000;
        console.log(`${secondsAheadOfServer}s ahead of server`);
        if (localUpdatedAt == null || secondsAheadOfServer < -10) {
          console.log("pulling bookmarks");
          await pullBookmarks(serverUpdatedAt, setBookmarks);
        }
      });
    }
  }, [session]);

  const workspaces = Array.from(
    new Set(
      bookmarks.flatMap((bookmark) =>
        Object.values(bookmark.tags).flatMap((workspaces) => workspaces)
      )
    )
  );

  const onInputChange: OnInputChangeType = (e) => {
    const text = e.target.value;
    setInputValue(text);
  };

  useEffect(() => {
    const localData = localStorage.getItem(localStorageBookmarksKey);
    const localBookmarks = localData ? JSON.parse(localData) : [];
    if (JSON.stringify(localBookmarks) !== JSON.stringify(bookmarks)) {
      localStorage.setItem(localStorageBookmarksKey, JSON.stringify(bookmarks));
      console.log({ msg: "in useEffect", bookmarks });
      pushBookmarks(bookmarks)
        .then(() => console.log("pushed"))
        .catch(console.error);
    }
  }, [bookmarks]);

  useEffect(() => {
    localStorage.setItem(localStorageCurrentWorkspaceKey, currentWorkspace);
  }, [currentWorkspace]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (isOmniboxDominant && document.activeElement !== inputRef.current) {
        console.log({ eventKey: event.key });
        event.preventDefault();
        (inputRef.current as unknown as HTMLInputElement)?.focus();
        setInputValue((prevValue) => prevValue + event.key);
      }
    };
    window.addEventListener("keypress", handleKeyPress);
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        setInputValue("");
      }
    });
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [isOmniboxDominant]);

  const onFormSubmit: OnFormSubmitType = (e) => {
    e.preventDefault();
    setIsSearchRequested(true);
  };

  const onDeleteRequested = (idx: number) => {
    console.log("onDeleteRequested");
    const newBookmarks = bookmarks.filter((_, i) => i !== idx);
    setBookmarks(newBookmarks);
  };

  const onSaveButtonClicked = (idx: number, updatedBookmark: Bookmark) => {
    console.log("onUpdated");
    const newBookmarks = [...bookmarks];
    newBookmarks[idx] = updatedBookmark;
    console.log({ message: "onSaveButtonClicked", idx, newBookmarks });
    setBookmarks(newBookmarks);
  };

  const onUploadClicked = () => {
    console.log("onUploadClicked");
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result;
          if (typeof content === "string") {
            const newBookmarks = JSON.parse(content);
            console.log({ newBookmarks });
            setBookmarks(newBookmarks);
          }
        };
        reader.readAsText(file);
      }
    };
    fileInput.click();
  };

  const onDownloadClicked = () => {
    console.log("onDownloadClicked");
    const blob = new Blob([JSON.stringify(bookmarks)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "bookmarks.json";
    a.click();
  };

  return (
    <div className="container">
      <Omnibox
        onFormSubmit={onFormSubmit}
        onInputChange={onInputChange}
        inputRef={inputRef}
        inputValue={inputValue}
      />
      <div className="workspaces">
        {workspaces.map((workspace) => (
          <button
            key={workspace}
            onClick={() => setCurrentWorkspace(workspace)}
            className={`workspace-btn ${
              workspace === currentWorkspace ? "workspace-selected" : ""
            }`}
          >
            {workspace}
          </button>
        ))}
        <button
          onClick={() => setCurrentWorkspace("")}
          className={`workspace-btn ${
            currentWorkspace === "" ? "workspace-selected" : ""
          }`}
        >
          all
        </button>
      </div>
      <BookmarksBrowseView
        bookmarks={bookmarks}
        onDeleteRequested={onDeleteRequested}
        onSaveButtonClicked={onSaveButtonClicked}
        setBookmarks={setBookmarks}
        currentWorkspace={currentWorkspace}
        searchQuery={inputValue}
        setIsOmniboxDominant={setIsOmniboxDominant}
        isSearchRequested={isSearchRequested}
        setIsSearchRequested={setIsSearchRequested}
      />
      <div className="footer">
        <div className="footer-section">
          <button className="upload-btn" onClick={onUploadClicked}>
            Upload
          </button>
          <button className="download-btn" onClick={onDownloadClicked}>
            Download
          </button>
        </div>
        <LogInComponent
          supabase={supabase}
          session={session}
          setIsOmniboxDominant={setIsOmniboxDominant}
        />
      </div>
    </div>
  );
}

export default App;
