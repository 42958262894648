import "./Omnibox.css";

export type OnFormSubmitType = (
  event: React.FormEvent<HTMLFormElement>
) => void;
export type OnInputChangeType = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

function Omnibox(props: {
  onFormSubmit: OnFormSubmitType;
  onInputChange: OnInputChangeType;
  inputRef: React.RefObject<HTMLInputElement>;
  inputValue: string;
}) {
  const { onFormSubmit, onInputChange, inputRef, inputValue } = props;
  return (
    <form onSubmit={onFormSubmit}>
      <input
        ref={inputRef}
        className="omnibox-input"
        type="text"
        id="url"
        onChange={onInputChange}
        placeholder="Insearch a bookmark..."
        value={inputValue}
      />
    </form>
  );
}

export default Omnibox;
