import { useEffect, useState } from "react";
import { Bookmark } from "./App";
import "./BookmarksBrowseView.css";
import BookmarkItem from "./BookmarkItem";
import TagHeader from "./TagHeader";

function formatUrl(originalUrl: string) {
  // remove the protocol part of the url string
  let url = originalUrl.replace(/(^\w+:|^)\/\//, "");

  // remove the www. prefix
  url = url.replace(/^(www\.)/, "");

  let parts = url.split("/");
  if (parts.length > 2) {
    parts.pop(); // remove the last segment
    return parts.join("/") + "...";
  } else {
    const urlParts = url.split("?");
    if (urlParts.length > 1) {
      return urlParts[0] + "...";
    } else {
      return url;
    }
  }
}

interface BookmarksBrowseViewProps {
  bookmarks: Bookmark[];
  onDeleteRequested: (idx: number) => void;
  onSaveButtonClicked: (idx: number, updatedBookmark: Bookmark) => void;
  setBookmarks: (bookmarks: Bookmark[]) => void;
  currentWorkspace: string;
  searchQuery?: string;
  setIsOmniboxDominant: (isOmniboxDominant: boolean) => void;
  isSearchRequested: boolean;
  setIsSearchRequested: (isSearchRequested: boolean) => void;
}

function BookmarksBrowseView({
  bookmarks,
  onDeleteRequested,
  onSaveButtonClicked,
  setBookmarks,
  currentWorkspace,
  searchQuery,
  setIsOmniboxDominant,
  isSearchRequested,
  setIsSearchRequested,
}: BookmarksBrowseViewProps) {
  const [expandedIdx, setExpandedIdx] = useState<number | null>(null);

  useEffect(() => {
    setIsOmniboxDominant(expandedIdx == null);
    console.log({ isOmniboxDominant: expandedIdx == null });
  }, [expandedIdx, setIsOmniboxDominant]);

  const onEditButtonClick =
    (idx: number) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      console.log("Edit button clicked");
      if (expandedIdx === idx) {
        setExpandedIdx(null);
      } else {
        setExpandedIdx(idx);
      }
    };

  const onDeleteClickedInternal =
    (idx: number) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      console.log("Delete button clicked");
      onDeleteRequested(idx);
      setExpandedIdx(null);
    };

  const onSaveButtonClickedInternal =
    (idx: number) => (updatedBookmark: Bookmark) => {
      console.log("Save button clicked");
      onSaveButtonClicked(idx, updatedBookmark);
      setExpandedIdx(null);
    };

  const allWorkspacesByTagName: Record<string, string[]> = {};

  bookmarks.forEach((bookmark) => {
    const workspacesByTagName = bookmark.tags;
    Object.keys(workspacesByTagName).forEach((tagName) => {
      // unique
      if (allWorkspacesByTagName[tagName]) {
        allWorkspacesByTagName[tagName] = Array.from(
          new Set([
            ...allWorkspacesByTagName[tagName],
            ...workspacesByTagName[tagName],
          ])
        );
      } else {
        allWorkspacesByTagName[tagName] = workspacesByTagName[tagName];
      }
    });
  });

  const bookmarkIdxByTagName = bookmarks.reduce((acc, bookmark, idx) => {
    const workspacesByTagName = bookmark.tags;
    const tagNames = Object.keys(workspacesByTagName);
    for (const tagName of tagNames) {
      const workspaces = workspacesByTagName[tagName];
      if (acc[tagName]) {
        acc[tagName].push({ idx, workspaces });
      } else {
        acc[tagName] = [{ idx, workspaces }];
      }
    }
    return acc;
  }, {} as Record<string, Array<{ idx: number; workspaces: string[] }>>);

  const relevantBookmarkIdxes: Array<
    [string, Array<{ idx: number; workspaces: string[] }>]
  > = Object.entries(bookmarkIdxByTagName).filter(([tagName, obj]) => {
    if (currentWorkspace === "") {
      return true;
    }
    return obj.some(({ workspaces }) => workspaces.includes(currentWorkspace));
  });

  let firstMatchingResult: Bookmark | undefined;
  relevantBookmarkIdxes.forEach(([tagName, obj]) => {
    if (firstMatchingResult != null) {
      return;
    }
    obj.find(({ idx }) => {
      if (isMatchSearchQuery(bookmarks[idx])) {
        firstMatchingResult = bookmarks[idx];
        return true;
      }
      return false;
    });
  });

  useEffect(() => {
    if (isSearchRequested) {
      if (firstMatchingResult != null) {
        window.open(firstMatchingResult.url, "_blank");
      } else if (searchQuery != null) {
        const newBookmark: Bookmark = {
          label: formatUrl(searchQuery),
          url: searchQuery,
          tags: {},
        };
        const newBookmarks = [...bookmarks, newBookmark];
        setBookmarks(newBookmarks);
      }
      setIsSearchRequested(false);
    }
  }, [isSearchRequested]);

  const bookmarkIdxWithNoTags: number[] = bookmarks.reduce((acc, _, idx) => {
    if (Object.keys(bookmarks[idx].tags).length === 0) {
      acc.push(idx);
    }
    return acc;
  }, [] as number[]);

  const onTagRenamed = (oldTag: string, newTag: string) => {
    console.log({ oldTag, newTag });
    // update all bookmarks so oldTag is renamed to newTag and no duplicates allowed in tag list
    const newBookmarks = bookmarks.map((bookmark) => {
      if (bookmark.tags[oldTag] == null) {
        return bookmark;
      }
      const originalTagData = bookmark.tags[oldTag];
      delete bookmark.tags[oldTag];
      if (bookmark.tags[newTag]) {
        bookmark.tags[newTag] = Array.from(
          new Set([...bookmark.tags[newTag], ...originalTagData])
        );
      } else {
        bookmark.tags[newTag] = originalTagData;
      }
      return bookmark;
    });

    console.log({ newBookmarks });
    setBookmarks(newBookmarks);
  };

  function isMatchSearchQuery(bookmark: Bookmark) {
    if (searchQuery == null || searchQuery === "") {
      return true;
    }
    return (
      bookmark.label.toLowerCase().includes(searchQuery.toLowerCase()) ||
      bookmark.url.toLowerCase().includes(searchQuery.toLowerCase()) ||
      Object.keys(bookmark.tags).some((tag) =>
        tag.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }

  return (
    <div>
      {bookmarkIdxWithNoTags.length > 0 && (
        <section>
          <h2>misc</h2>
          <ul>
            {bookmarkIdxWithNoTags.map((idx) => (
              <BookmarkItem
                key={idx}
                allExistingTags={Object.keys(allWorkspacesByTagName)}
                bookmark={bookmarks[idx]}
                isActive={idx === expandedIdx}
                onEditButtonClick={onEditButtonClick(idx)}
                onSaveButtonClicked={onSaveButtonClickedInternal(idx)}
                onDeleteRequested={onDeleteClickedInternal(idx)}
                isOnCursor={
                  searchQuery != null &&
                  searchQuery !== "" &&
                  bookmarks[idx] === firstMatchingResult
                }
              />
            ))}
          </ul>
        </section>
      )}
      {relevantBookmarkIdxes.map(([tagName, obj]) => {
        return (
          obj.some(({ idx }) => isMatchSearchQuery(bookmarks[idx])) && (
            <section key={tagName}>
              <TagHeader
                tagName={tagName}
                superTags={allWorkspacesByTagName[tagName] || []}
                onTagRenamed={onTagRenamed}
              />
              <ul>
                {obj.map(
                  ({ idx, workspaces }) =>
                    isMatchSearchQuery(bookmarks[idx]) && (
                      <BookmarkItem
                        key={idx}
                        allExistingTags={Object.keys(allWorkspacesByTagName)}
                        bookmark={bookmarks[idx]}
                        isActive={idx === expandedIdx}
                        onEditButtonClick={onEditButtonClick(idx)}
                        onSaveButtonClicked={onSaveButtonClickedInternal(idx)}
                        onDeleteRequested={onDeleteClickedInternal(idx)}
                        isOnCursor={
                          searchQuery != null &&
                          searchQuery !== "" &&
                          bookmarks[idx] === firstMatchingResult
                        }
                      />
                    )
                )}
              </ul>
            </section>
          )
        );
      })}
    </div>
  );
}

export default BookmarksBrowseView;
