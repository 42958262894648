import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useState } from "react";
import { OnFormSubmitType } from "./Omnibox";

interface LogInComponentProps {
  supabase: SupabaseClient<any, "public", any>;
  session: Session | null;
  setIsOmniboxDominant: (isOmniboxDominant: boolean) => void;
}

function LogInComponent({
  supabase,
  session,
  setIsOmniboxDominant,
}: LogInComponentProps) {
  const [isEmailRequested, setIsEmailRequested] = useState(false);
  const [notification, setNotification] = useState("");
  const [email, setEmail] = useState("");
  const [isLogOutRequested, setIsLogOutRequested] = useState(false);

  const onTryAgainClicked = () => {
    setNotification("");
    setIsEmailRequested(false);
    if (session) {
      supabase.auth.signOut();
      setIsLogOutRequested(true);
    }
  };

  const onLogInClicked: OnFormSubmitType = async (e) => {
    e.preventDefault();
    setIsEmailRequested(true);
    setIsLogOutRequested(false);

    const { data, error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: process.env.REACT_APP_SUPABASE_EMAIL_REDIRECT_TO,
      },
    });

    if (error) {
      setNotification(error.message);
    } else {
      setNotification("Check your email.");
    }
  };

  return (
    <div className="footer-section">
      {!isLogOutRequested && session ? (
        <button className="log-out-btn" onClick={onTryAgainClicked}>
          Log Out
        </button>
      ) : isEmailRequested ? (
        <>
          <p className="login-notification">{notification}</p>
          {notification.length > 0 && (
            <button className="log-in-btn" onClick={onTryAgainClicked}>
              Try Again
            </button>
          )}
        </>
      ) : (
        <form onSubmit={onLogInClicked}>
          <input
            type="text"
            placeholder="Sync with email"
            className="log-in-email-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setIsOmniboxDominant(false)}
            onBlur={() => setIsOmniboxDominant(true)}
          />
          <button className="log-in-btn">Log In</button>
        </form>
      )}
    </div>
  );
}

export default LogInComponent;
