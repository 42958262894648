import { useEffect, useRef, useState } from "react";
import { Bookmark } from "./App";
import TagsInput from "./TagsInput";

interface BookmarkItemProps {
  isActive: boolean;
  bookmark: Bookmark;
  onEditButtonClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDeleteRequested: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onSaveButtonClicked: (newBookmark: Bookmark) => void;
  isOnCursor: boolean;
  allExistingTags: string[];
}

function getFavicon({ domain, size }: { domain: string; size: number }) {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size}`;
}

function BookmarkItem({
  isActive,
  bookmark,
  onEditButtonClick,
  onDeleteRequested,
  onSaveButtonClicked,
  isOnCursor,
  allExistingTags,
}: BookmarkItemProps) {
  const {
    label: originalName,
    tags: originalTags,
    url: originalUrl,
  } = bookmark;
  const [label, setLabel] = useState(originalName);
  const [url, setUrl] = useState(originalUrl);
  const [tags, setTags] = useState(originalTags);

  const onInput = (e: React.FormEvent<HTMLAnchorElement>) => {
    console.log("onInput");
    const text = e.currentTarget.textContent;
    setLabel(text || "");
    const isEnterPressed =
      (e.nativeEvent as InputEvent).inputType === "insertParagraph" ||
      (e.nativeEvent instanceof KeyboardEvent && e.nativeEvent.key === "Enter");

    console.log({ event: e.nativeEvent, isEnterPressed });

    if (isEnterPressed) {
      onSaveButtonClickInternal(undefined);
    }
  };

  const onSaveButtonClickInternal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined
  ) => {
    const newBookmark: Bookmark = { ...bookmark, label, url, tags };
    onSaveButtonClicked(newBookmark);
  };

  const onAddTag = (tag: string) => {
    setTags({ ...tags, [tag]: [] });
  };

  const onRemoveTag = (index: number) => {
    const newTags = { ...tags };
    delete newTags[index];
    setTags(newTags);
  };

  const liRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isActive &&
        liRef.current != null &&
        (liRef.current as HTMLElement).contains(event.target as Node) === false
      ) {
        onSaveButtonClickInternal(undefined);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [liRef, isActive]);

  return (
    <li
      ref={liRef}
      className={`bookmark-li ${isActive ? "expanded-bookmark-li" : ""}`}
    >
      {isOnCursor && <div className="bookmark-cursor">&gt;</div>}
      <img src={getFavicon({ domain: url, size: 28 })} alt="Favicon" />
      <a
        contentEditable={isActive}
        suppressContentEditableWarning={isActive}
        href={url}
        onInput={onInput}
      >
        {originalName}
      </a>
      {isActive ? (
        <>
          <button className="delete-bookmark-btn" onClick={onDeleteRequested}>
            Delete
          </button>
          <button
            onClick={onSaveButtonClickInternal}
            className="save-bookmark-button"
          >
            Save
          </button>
        </>
      ) : (
        <div className="edit-bookmark-div" onClick={onEditButtonClick}>
          <span style={{ verticalAlign: "middle" }}>Edit</span>
        </div>
      )}
      {isActive && (
        <div className="expanded-li">
          <input
            className="bookmark-url-input"
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <TagsInput
            allExistingTags={allExistingTags}
            tags={Object.keys(tags)}
            addTag={onAddTag}
            removeTag={onRemoveTag}
          />
        </div>
      )}
    </li>
  );
}

export default BookmarkItem;
